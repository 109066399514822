export const load = (item: string) => {
    try {
        return localStorage.getItem(item);
    } catch (err) {
        return null;
    }
};
export const save = (item: string, data: any) => {
    try {
        localStorage.setItem(item, data);
    } catch (err) {
        //Ignore write when is error
    }
};
export const loadObject = (item: string) => {
    try {
        const serializedState = localStorage.getItem(item);
        return JSON.parse(serializedState ? serializedState : "");
    } catch (err) {
        return null;
    }
};
export const saveObject = (item: string, state: any) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem(item, serializedState);
    } catch (err) {
        //Ignore write when is error
    }
};
export const deleteItem = (item: string) => {
    try {
        localStorage.removeItem(item);
    } catch (err) {
        //Ignore write when is error
    }
};

export const clear = () => {
    try {
        localStorage.clear();
    } catch (err) {
        //Ignore write when is error
    }
};
