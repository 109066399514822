import { HubspotProcess } from "../../api";
import { HubspotConnect } from "../../interfaces";
import { HubspotTypes } from "../types";

export const hubspotConnectAction = (data: HubspotConnect) => async (dispatch: (arg: Record<string, any>) => any) => {
    dispatch({
        type: HubspotTypes.HUBSPOT_CONNECT_LOADING,
        payload: {
            loading: true,
        },
    });
    try {
        const hubspotProcess = new HubspotProcess();
        const auth = await hubspotProcess.connectHubspot(data);
        return dispatch({
            type: HubspotTypes.HUBSPOT_CONNECT_SUCCESS,
            payload: {
                authHubspot: auth,
                loading: false,
                success: true,
            },
        });
    } catch (error) {
        return dispatch({
            type: HubspotTypes.HUBSPOT_CONNECT_FAILURE,
            payload: {
                loading: false,
                success: false,
            },
        });
    }
};
