import { AnyAction } from "redux";
import { AuthStore } from "../../interfaces";
import { AuthTypes } from "../types/auth.types";

const initialState: AuthStore = {
    user: null,
    token: null,
    isLoggedIn: false,
    loading: false,
    success: true,
};

export const auth = (state = initialState, action: { type: string; payload: AuthStore } | AnyAction): AuthStore => {
    const { loading, success, user, token, role, userRoles, isLoggedIn } = action.payload || {};
    switch (action.type) {
        case AuthTypes.LOGIN_USER_LOADING:
            return {
                ...state,
                loading,
            };
        case AuthTypes.LOGIN_USER_SUCCESS:
            return {
                ...state,
                user,
                token,
                isLoggedIn,
                loading,
                success,
            };
        case AuthTypes.LOGIN_USER_FAILURE:
            return {
                ...state,
                isLoggedIn: false,
                loading,
                success,
            };
        default:
            return state;
    }
};
