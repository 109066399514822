import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { hubspotConnectAction } from "../redux/actions";
export const HubspotPage = (): JSX.Element => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const useQuery = () => {
        return new URLSearchParams(window.location.search);
    };
    const query = useQuery();
    useEffect(() => {
        connectHubspot();
    }, []);

    function randomString(length: number): string {
        let result = "";
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
    const connectHubspot = async () => {
        const params = Object.fromEntries(query);
        if (Object.entries(params).length > 0 && params.code) {
            setLoading(true);
            const res = {
                ...(await dispatch(
                    hubspotConnectAction({
                        externalId: process.env.REACT_APP_HUBSPOT_EXTERNAL_ID + "-" + randomString(10) || "",
                        name: process.env.REACT_APP_HUBSPOT_NAME || "",
                        lastname: process.env.REACT_APP_HUBSPOT_LASTNAME || "",
                        codeHubspot: params.code,
                    }),
                )),
            } as {
                payload: {
                    loading: boolean;
                    success: boolean;
                };
            };
            if (res.payload.success) {
                setMessage("Autenticación exitosa");
            } else {
                setMessage("Tuvimos un problema. Por favor, intenta de nuevo.");
            }
            setLoading(false);
        }
    };
    return (
        <div className="App">
            <header className="App-header">
                <p>Hubspot</p>
                {loading ? (
                    <CircularProgress
                        color="primary"
                        style={{
                            position: "fixed",
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            margin: "auto",
                        }}
                    />
                ) : (
                    <p>{message} </p>
                )}
            </header>
        </div>
    );
};
