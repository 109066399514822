import React from "react";

import "./App.css";

import { Provider } from "react-redux";
import { store } from "./redux/store";
import { ThemeProvider } from "@material-ui/core";
import theme from "./theme";
import { AppRouter } from "./routers/AppRouter";

const App = (): JSX.Element => {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <AppRouter />
            </ThemeProvider>
        </Provider>
    );
};

export default App;
