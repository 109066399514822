import { HubspotRepository } from "..";
import { HubspotConnect, HubspotResponse } from "../../interfaces";

export class HubspotProcess {
    hubspotRepository: HubspotRepository;

    constructor() {
        this.hubspotRepository = new HubspotRepository();
    }
    async connectHubspot(data: HubspotConnect): Promise<HubspotResponse> {
        const response = await this.hubspotRepository.connectHubspot(data);
        return response.data;
    }
}
