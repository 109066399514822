import axios, { AxiosInstance } from "axios";
import * as localStorage from "../utils/localStorage";
import { Token } from "../interfaces";

export const getToken = (): string => {
    const token: Token = localStorage.loadObject("token");
    if (token) {
        return token.accessToken;
    }
    return "LMBOOTS";
};
export const fetcher = (urlApi: string): AxiosInstance => {
    const API = axios.create({
        baseURL: urlApi,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/vnd.radarapi.v1+json",
        },
    });

    API.interceptors.request.use(async function (config) {
        const token = getToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    });
    return API;
};
