import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { HomePage } from "../pages/HomePage";
import { HubspotPage } from "../pages/HubspotPage";
export const AppRouter = (): JSX.Element => {
    return (
        <Router>
            <Switch>
                <Route path="/hubspot">
                    <HubspotPage />
                </Route>
                <Route path="/">
                    <HomePage />
                </Route>
            </Switch>
        </Router>
    );
};
