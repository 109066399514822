import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../../configurations";
import { HubspotConnect, HubspotResponse } from "../../interfaces/hubspot.interface";

export class HubspotRepository {
    apiUrl: AxiosInstance;

    constructor() {
        this.apiUrl = fetcher(process.env.REACT_APP_URL_API || "");
    }
    connectHubspot(data: HubspotConnect): Promise<AxiosResponse<HubspotResponse>> {
        return this.apiUrl.post<HubspotResponse>("/hubspot/connect", data);
    }
}
